import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Connect from "./Connect";

const Wrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("md")]: {
    h5: {
      fontSize: 16,
      margin: 0,
    },
  },
}));

export default function Header() {
  return (
    <Wrapper>
      <div class="container">
        <h1 class="neonText">
              #hashCandy
        </h1>
      </div>
      <Connect responsive={false} />
      <Typography variant="h4">
        Welcome to the sweetest BNB reward pool. Participate now and enjoy APR of ~ <strong><font color="#f09">2920%</font></strong> and <strong><font color="#f09">8%</font></strong> daily returns.
      </Typography>
    </Wrapper>
  );
}
